import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import { BaseComponent, Component, Prop, namespace } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import { CustomFormsStore } from 'theme/stores/customForms/customForms';
import type { FormData } from 'theme/stores/cms/types';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';

import { Button, ButtonStyle } from '../../atom/Button/Button';
import { InnerHTML } from '../../atom/InnerHTML';
import { Overlay } from '../../atom/Overlay/Overlay';

import { FormFields } from './FormFields';
import type { ICustomForms } from './CustomForms.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appContextKey);

export type ProductData = {
  'product-name'?: string;
  'product-sku'?: string;
  'product-price'?: number;
};

@Component({
  mixins: [onEscapePress],
})
export class CustomForms extends BaseComponent<ICustomForms, unknown> {
  /**
   * Object containing necessary information for fetching forms
   */
  @Prop({ type: Object, required: true })
  formData: FormData;

  /**
   * Object containing necessary information for sending product additional information on submit
   */
  @Prop({ type: Object, required: false, default: () => ({}) })
  productData?: ProductData;

  /**
   * Array of current product categories names
   */
  @Prop({ type: Array, required: false, default: () => [] })
  productCategories?: string[];

  /**
   * Path to image
   */
  @Prop({ type: String, required: false, default: undefined })
  drawerImage?: string;

  /**
   * Determine if form is loaded in drawer or directly on page
   * Necessary property for product page
   */
  @Prop({ type: Boolean, required: false })
  isDrawer?: boolean;

  /**
   * Determine what type of button should be rendered
   */
  @Prop({ type: String, required: false, default: 'primary' })
  btnStyleType?: ButtonStyle;

  protected formStore = new CustomFormsStore();

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  data() {
    return {
      active: false,
      formFieldsData: [],
    };
  }

  beforeMount() {
    this.$on('close-form-drawer', () => {
      this.closeDrawer();
    });
  }

  async mounted() {
    if (this.formData.is_drawer !== '1' && !this.isDrawer) {
      this.broadcast('notification-progress-start');
      await this.fetchFormData().then(() => this.broadcast('notification-progress-stop'));
    }
  }

  beforeDestroy() {
    this.$off('close-form-drawer');
  }

  render() {
    const formConfig = this.extended.$config.zento.images.customForms;

    return this.formData.is_drawer === '1' || this.isDrawer ? (
      <div class={style.container}>
        {this.formData.heading ? (
          <h2
            class={{
              [style.title]: true,
              [style.centerTitle]: this.formData.heading_position === 'center',
              [style.rightTitle]: this.formData.heading_position === 'right',
            }}>
            {this.formData.heading}
          </h2>
        ) : null}

        {this.formData.description ? (
          <InnerHTML contents={decodePhpHtmlString(this.formData.description)} class={style.description} />
        ) : null}

        <Button
          styleType={this.formData.button_type || this.btnStyleType}
          handler={this.openDrawer}
          class={{ [style.formBtn]: true, [style.btnNoStyle]: !this.btnStyleType }}>
          {this.formData.button_label}
        </Button>

        <transition
          name='slide-in-right'
          enterActiveClass={style.slideInRightEnterActive}
          leaveActiveClass={style.slideInRightLeaveActive}>
          {this.$data.active ? (
            <div class={style.customFormDrawer} key='custom-form-drawer'>
              <h2 class={style.customFormDrawerTitle}>
                {this.formData.button_label}
                <span onClick={this.closeDrawer} class={style.closeDrawer} />
              </h2>
              <FormFields
                formFields={this.formFields}
                productData={this.productData}
                formTitle={this.formData.title}
                formId={this.formData.form_id}
                productCategories={this.productCategories}
                drawerImage={this.formData.image || this.drawerImage}
                imageFolder={typeof this.formData.image !== 'undefined'}
                btnLabel={this.customFormData?.submitLabel || 'Send'}
                preSignedPost={this.preSignedPost || []}
                termsAndConditions={this.customFormData?.termsAndConditions}
                key='form-fields-drawer'
              />
            </div>
          ) : null}
        </transition>

        {this.$data.active ? (
          <span onClick_capture={this.closeDrawer} key='custom-form-overlay'>
            <Overlay class={style.showOverlay} />
          </span>
        ) : null}
      </div>
    ) : (
      <div
        class={{
          [style.container]: true,
          [style.containerSmall]: this.formData.width === 'small',
          [style.largeContainer]: this.formData.width === 'large',
          [style.fullContainer]: this.formData.width === 'full_width',
        }}>
        {this.formData.heading ? (
          <h2
            class={{
              [style.title]: true,
              [style.centerTitle]: this.formData.heading_position === 'center',
              [style.rightTitle]: this.formData.heading_position === 'right',
            }}>
            {this.formData.heading}
          </h2>
        ) : null}

        {this.formData.description ? (
          <InnerHTML contents={decodePhpHtmlString(this.formData.description)} class={style.description} />
        ) : null}

        {formConfig.enableTitle ? (
          <h3
            class={{
              [style.formTitle]: true,
              [style.centerTitle]: this.formData.heading_position === 'center',
              [style.rightTitle]: this.formData.heading_position === 'right',
            }}>
            {this.formData.title}
          </h3>
        ) : null}

        <FormFields
          formFields={this.formFields}
          productData={this.productData}
          formTitle={this.formData.title}
          formId={this.formData.form_id}
          productCategories={this.productCategories}
          drawerImage={this.formData.image || this.drawerImage}
          imageFolder={typeof this.formData.image !== 'undefined'}
          btnLabel={this.customFormData?.submitLabel || 'Send'}
          termsAndConditions={this.customFormData?.termsAndConditions}
          preSignedPost={this.preSignedPost || []}
          class={style.formContainer}
          key='form-fields-drawer'
        />
      </div>
    );
  }

  /**
   * On escape press hide drawer
   **/
  protected onEscapePress() {
    this.closeDrawer();
  }

  /**
   * Retrieve form data
   */
  private get customFormData() {
    return this.formStore.getFormByIdentifier(parseInt(this.formData.form_id));
  }

  /**
   * Retrieve form fields
   */
  private get formFields() {
    this.$data.formFieldsData = this.customFormData ? JSON.parse(this.customFormData.fields) : [];

    return this.$data.formFieldsData;
  }

  /**
   * Retrieve form pre signed post
   */
  private get preSignedPost() {
    return this.customFormData && this.customFormData.preSignedPost
      ? JSON.parse(this.customFormData.preSignedPost)
      : {};
  }

  /**
   * Fetches form data
   */
  private async fetchFormData() {
    if (!this.customFormData) {
      return await this.formStore.fetchCustomForms(parseInt(this.formData.form_id));
    }
  }

  /**
   * Handler for opening drawer
   */
  private async openDrawer() {
    this.broadcast('notification-progress-start');
    await this.fetchFormData().then(() => this.broadcast('notification-progress-stop'));
    this.$data.active = true;
    document.body.style.overflow = 'hidden';

    if (!this.isServer && this.productData) {
      this.broadcast('analytics/product-custom-form', {
        product: { ...this.productData, categories: this.productCategories },
      });
    }
  }

  /**
   * Handler for closing drawer
   */
  private closeDrawer() {
    this.$data.active = false;
    document.body.style.overflow = 'visible';
  }
}
