import { BaseComponent, Component, Prop, namespace, Watch } from '@zento-lib/components';
import { CustomFormsStore } from 'theme/stores/customForms/customForms';
import { Form } from 'theme/components/Form/Form';
import { Input } from 'theme/components/Form/Input';
import { Select } from 'theme/components/Form/Select';
import { Checkbox } from 'theme/components/Form/Checkbox';
import { RadioGroup } from 'theme/components/Form/RadioGroup';
import type { IFormValidation } from 'theme/components/Form/types';
import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { FormFieldsTypes } from '@zento/modules/organism/types/FormData';

import { CmsBlock } from '../../molecule/CmsBlock/CmsBlock';
import { Button } from '../../atom/Button/Button';
import { MainImage } from '../../atom/MainImage/MainImage';
import { TermsAndConditions } from '../../atom/TermsAndConditions/TermsAndConditions';

import type { ProductData } from './CustomForms';
import type { IFormFields } from './FormFields.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

@Component({})
@Form()
export class FormFields extends BaseComponent<IFormFields, unknown & IFormValidation> {
  private static T = {
    registerButtonLabel: 'register_button_label',
    reviewTerms: 'terms_and_conditions',
    reviewPrivacyPolicy: 'review_privacy_policy',
    reviewAgreedAnd: 'review_agreed_and',
  };

  /**
   * Array of all form fields
   */
  @Prop({ type: Array, required: true })
  formFields: FormFieldsTypes[];

  /**
   * Object containing necessary information for sending product additional information on submit
   */
  @Prop({ type: Object, required: false, default: () => ({}) })
  productData?: ProductData;

  /**
   * Array of current product categories names
   */
  @Prop({ type: Array, required: false, default: () => [] })
  productCategories?: string[];

  /**
   * Path to image
   */
  @Prop({ type: String, required: false, default: undefined })
  drawerImage?: string;

  /**
   * Determine if image path comes from cms page or product page
   */
  @Prop({ type: Boolean, required: false })
  imageFolder?: boolean;

  /**
   * Label for submit button
   */
  @Prop({ type: String, required: false, default: '' })
  btnLabel?: string;

  /**
   * Determine if terms checkbox is visible
   */
  @Prop({ type: Number, required: false, default: 0 })
  termsAndConditions?: number;

  /**
   * Custom form title to be send in request
   */
  @Prop({ type: String, required: true, default: '' })
  formTitle: string;

  /**
   * Custom form id to be send in request
   */
  @Prop({ type: String, required: true, default: '' })
  formId: string;

  /**
   * Object of all form pre signed post
   */
  @Prop({ type: Object, required: true, default: () => ({}) })
  preSignedPost: FormFieldsTypes;

  @appContextStore.Getter('isServer')
  protected isServer: boolean;

  protected formStore = new CustomFormsStore();

  data() {
    return {
      conditions: false,
      formFieldItems: [],
      values: {},
    };
  }

  @Watch('formId')
  async formIdChanged(newVal: number, oldVal: number) {
    if (this.extended.$config.storeViews.multistore && newVal !== oldVal) {
      this.broadcast('notification-progress-start');
      this.$data.formFieldItems = []; // Make sure form items are empty because store has changed and there is no cache
      await this.formStore.fetchCustomForms(newVal).then(() => this.broadcast('notification-progress-stop'));
    }
  }

  /**
   * Determines fields once form is submitted
   */
  private renderFields() {
    if (this.$data.formFieldItems !== null && !this.$data.formFieldItems.length) {
      this.formFields.forEach((f) => {
        const dropdownValues = this.removeSpecialChars(f.dropdown_values, '.');
        const fieldName = f.label ? f.label : dropdownValues;
        const value = this.removeSpecialChars(fieldName, '.');

        this.$data.values[value] = f[value];
      });

      if (this.termsAndConditions === 1) {
        this.$data.conditions = false;
      }

      this.$data.formFieldItems = [...this.formFields];
    }
  }

  /**
   * Remove special characters for rendering correct state value
   */
  private removeSpecialChars(value, char) {
    const fieldValue = value.replace(/[^a-zA-Z0-9]/g, char);

    return fieldValue;
  }

  beforeUpdate() {
    this.renderFields();
  }

  beforeMount() {
    this.renderFields();
  }

  render() {
    const isInvalid = !this.extended.zValidation.valid;
    const imgConfig = this.extended.$config.zento.images.customForms;
    const productThumbnail = this.extended.$config.zento.images.productsThumbnails;
    const config = this.extended.$config.zento.theme;

    return (
      <div class={style.formWrapper}>
        <div class={style.formContent}>
          {this.drawerImage ? (
            <MainImage
              image={{ src: this.drawerImage, loading: this.drawerImage }}
              width={this.imageFolder ? imgConfig.width : productThumbnail.width}
              height={this.imageFolder ? imgConfig.height : productThumbnail.height}
              tabletWidth={this.imageFolder ? imgConfig.tabletWidth : productThumbnail.tabletWidth}
              tabletHeight={this.imageFolder ? imgConfig.tabletHeight : productThumbnail.tabletHeight}
              desktopWidth={this.imageFolder ? imgConfig.desktopWidth : productThumbnail.desktopWidth}
              desktopHeight={this.imageFolder ? imgConfig.desktopHeight : productThumbnail.desktopHeight}
              folder={this.imageFolder ? '' : '/media/catalog/product'}
              alt={this.productData['product-name'] || ''}
              class={style.imageContainer}
            />
          ) : null}

          <form onSubmit={this.callSubmit} data-zento-form='true'>
            {[...(this.$data.formFieldItems || [])]
              .sort((a: FormFieldsTypes, b: FormFieldsTypes) => parseInt(a.position) - parseInt(b.position))
              .map((field: FormFieldsTypes) => {
                const dropdownValues = this.removeSpecialChars(field.dropdown_values, '.');
                const fieldName = field.label ? field.label : dropdownValues;
                const testId = this.removeSpecialChars(fieldName, '.') + '-' + field.type;
                const value = this.removeSpecialChars(fieldName, '.');

                return [
                  field.type !== 'select' && field.type !== 'checkbox' && field.type !== 'radio' ? (
                    <div
                      class={{
                        [style.file]: field.type === 'file',
                      }}>
                      <Input
                        state={this.$data.values}
                        valueKeeper={value}
                        name={fieldName}
                        type={field.type}
                        validateOn='input'
                        required={field.is_required === '1'}
                        minLength={field.type !== 'date' ? 2 : null}
                        pattern={field.pattern}
                        pristinePolicy={field.type !== 'date' ? 'poi' : 'poe'}
                        labelStyle={field.type === 'date' ? 'static-top' : config.labelStyle}
                        class={{ [style.date]: field.type === 'date' }}
                        key={field.label + field.type + '-input'}
                        dataTestId={testId}>
                        {field.type !== 'hidden' ? <span slot='label'>{field.label}</span> : null}
                      </Input>

                      {this.formStore.loaderImage && field.type === 'file' ? (
                        <div class={style.progress}>
                          <div class={style.progressValue} />
                        </div>
                      ) : null}
                    </div>
                  ) : field.type === 'select' ? (
                    <Select
                      state={this.$data.values}
                      valueKeeper={value}
                      name={fieldName}
                      items={field.dropdown_values.split(',').map((opt) => ({
                        value: this.removeSpecialChars(opt, '').toLocaleLowerCase(),
                        label: opt,
                      }))}
                      required={field.is_required === '1'}
                      key={field.label + '-select-input'}
                      dataTestId={testId}>
                      <span slot='label'>{field.label}</span>
                    </Select>
                  ) : field.type === 'checkbox' ? (
                    <Checkbox
                      state={this.$data.values}
                      valueKeeper={value}
                      name={fieldName}
                      type={field.type}
                      validateOn='change'
                      required={field.is_required === '1'}
                      checked={true}
                      label={field.label}
                      class={style.checkboxField}
                      key={field.label + '-checkbox-input'}
                      dataTestId={testId}
                    />
                  ) : field.type === 'radio' ? (
                    [
                      <span slot='label'>{field.label}</span>,
                      <RadioGroup
                        state={this.$data.values}
                        valueKeeper={value}
                        name={fieldName}
                        type='radio'
                        items={field.dropdown_values.split(',').map((opt) => ({
                          value: this.removeSpecialChars(opt, '').toLocaleLowerCase(),
                          label: opt,
                        }))}
                        validateOn='change'
                        required={field.is_required === '1'}
                        key={field.label + '-radio-input'}
                        dataTestId={testId}
                      />,
                    ]
                  ) : null,
                ];
              })}

            {this.termsAndConditions === 1 ? (
              <div class={style.termsWrapper}>
                <Checkbox
                  state={this.$data}
                  valueKeeper='conditions'
                  name='acceptConditions'
                  id='terms'
                  type='checkbox'
                  validateOn='change'
                  required={true}
                  checked={true}
                  key='conditions'
                  label={this.getTranslation({ id: FormFields.T.registerButtonLabel })}
                  class={style.checkbox}
                  dataTestId='customFormTerms'
                />
                <TermsAndConditions
                  key='agree-terms'
                  reviewTerms={{ id: FormFields.T.reviewTerms }}
                  reviewPrivacyPolicy={{ id: FormFields.T.reviewPrivacyPolicy }}
                  reviewAgreed={{ id: FormFields.T.reviewAgreedAnd }}
                  termsAndConditionsLink={
                    'termsAndConditionsRentPageLink' in config ? config.termsAndConditionsRentPageLink : ''
                  }
                  class={style.agreeBox}
                />
                {config.termsAndConditionsAdditional ? (
                  <CmsBlock
                    identifier={config.termsAndConditionsAdditional}
                    class={style.termsAndConditions}
                    key='terms-and-conditions'
                  />
                ) : null}
              </div>
            ) : null}

            <Button
              styleType='primary'
              type='submit'
              name='send-form-data'
              title={this.btnLabel}
              disabled={isInvalid}
              dataTestId='customFormSubmit'
              key='form-fields-btn'>
              <span>{this.btnLabel}</span>
            </Button>
          </form>
        </div>
      </div>
    );
  }

  /**
   * Submit form with data
   */
  private async callSubmit(ev: Event) {
    ev.preventDefault();

    const preSignedPost = this.preSignedPost;

    if (preSignedPost !== null && Object.keys(preSignedPost).length && 'action' in preSignedPost) {
      const inputs: HTMLInputElement[] = Array.from(this.$el.querySelectorAll('form[data-zento-form] input'));

      await this.formStore.formImageUploadFile(preSignedPost, inputs);
    }

    const content = Object.keys(this.$data.values).reduce(
      (ret, i) => ({
        ...ret,
        [i.replace(/\./g, ' ')]: this.$data.values[i],
      }),
      {},
    );

    await this.formStore.send(
      JSON.stringify({ ...content, ...this.productData }),
      this.formTitle,
      parseInt(this.formId),
      true,
    );

    this.$data.values = Object.keys(this.$data.values).reduce((ret, k) => ({ ...ret, [k]: '' }), {});
    this.$data.formFieldItems = null;

    setTimeout(() => {
      this.$data.formFieldItems = [];
    }, 10);

    if (!this.isServer && this.productData) {
      this.broadcast('analytics/lead-product', {
        product: { ...this.productData, categories: this.productCategories },
      });
    }

    this.$parent.$emit('close-form-drawer');
    document.body.style.overflow = 'visible';
  }
}
